import React from 'react'
import ImageWrap from 'components/ImageWrap'

export default function IntroBlock({ content }) {
  const { icon, title, description } = content

  return (
    <section className='multivariate-grid multivariate-grid--horizontal-clip title-copy-block-50-50 title-copy-block-50-50--has-image'>
      <div className='title-copy-block-50-50__container multivariate-grid__container'>
        <div className='tile-copy-block-50-50__row multivariate-grid__row'>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <section className='image'>
              <ImageWrap image={icon} />
            </section>

            <h2 className='title-copy-block-50-50__title'>{title}</h2>
          </div>
          <div className='title-copy-block-50-50__column multivariate-grid__column'>
            <div className='title-copy-block-50-50__copy-block'>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
