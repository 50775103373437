import React from 'react'
import ImageWrap from 'components/ImageWrap'
import PageLink from 'components/PageLink'

export default function GridLineButton({
  content,
  isCompact = false,
  buttonText,
}) {
  let sectionClass = `multivariate-grid multivariate-grid--vertical-clip simple-tile-grid simple-tile-grid--default`
  if (isCompact)
    sectionClass = `multivariate-grid multivariate-grid--vertical-clip simple-tile-grid simple-tile-grid--compact simple-tile-grid--pull-up`
  return (
    <section className={sectionClass}>
      <div className='simple-tile-grid__container multivariate-grid__container'>
        <div className='simple-tile-grid__row multivariate-grid__row'>
          {content.map((item, i) => (
            <GridItem item={item} key={i} buttonText={buttonText} />
          ))}
        </div>
      </div>
    </section>
  )
}

function GridItem({ item, buttonText }) {
  const { icon, title, description, pageLink } = item
  return (
    <div className='simple-tile-grid__column multivariate-grid__column'>
      <PageLink
        pageLink={pageLink}
        className='content-cta-image-card content-cta-image-card--pointer'
      >
        <div className='content-cta-image-card__content'>
          {icon && (
            <div className='figure'>
              <ImageWrap image={icon} />
            </div>
          )}
          <h4 className='content-cta-image-card__title'>{title}</h4>
          {description && (
            <div className='content-cta-image-card__excerpt'>
              <p>{description}</p>
              <p>&nbsp;</p>
            </div>
          )}
        </div>

        {(icon || pageLink) && (
          <div className='content-cta-image-card__image-cta-container'>
            {icon && (
              <div className='figure'>
                <ImageWrap image={icon} />
              </div>
            )}
            <div className='content-cta-image-card__cta-container'>
              {pageLink && (
                <div className='content-cta-image-card__cta'>{buttonText}</div>
              )}
            </div>
          </div>
        )}
      </PageLink>
    </div>
  )
}
