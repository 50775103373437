import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'components/Layout'
import IntroBlock from 'components/IntroBlock'
import GridLineButton from 'components/GridLineButton'
import { strings } from 'js/strings'

export default function Solutions2Template({ data }) {
  const page = data.wpPage
  return (
    <Layout
      bodyClassName='page-template page-template-page-builder page-builder page page-id-134 measure-optimize app-data index-data singular-data page-data page-134-data page-measure-optimize-data page-builder-data'
      page={page}
    >
      <IntroBlock content={page.template.solutions2.introBlock} />
      <GridLineButton
        content={page.template.solutions2.grid}
        buttonText={strings.readDetails}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wpPage(slug: { eq: $slug }) {
      title
      ...SeoPage
      ...BannerPage
      ...RelatedBlockPage
      ...ContactBlockPage
      template {
        templateName
        ... on WpTemplate_Solutions2 {
          solutions2 {
            introBlock {
              icon {
                ...GatsbyImage
              }
              title
              description
            }
            grid {
              title
              description
              pageLink {
                ...PageLinkPageInner
                ...PageLinkPostInner
              }
            }
          }
        }
      }
    }
  }
`
